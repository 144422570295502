import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMetaTags } from "react-metatags-hook";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Box, Container, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Product as ProductModel,
  FilterProduct as FilterProductModel,
} from "../../models/Product";
import { SubProduct as SubProductModel } from "../../models/SubProduct";
import { GetProductById as GetProductByIdService } from "../../services/Product";
import { ColorPatterns as ColorPatternsConstant } from "../../constants/Product";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import GeneralLayout from "../../layouts/General";
import { Paths as PathsConstant } from "../../constants/Route";
import LineGraphChart from "../../components/charts/LineGraph";
import ProductPriceCompareTable from "../../components/tables/ProductPriceCompare";
import ProductFilter from "../../components/filters/Product";
import GoogleAdsense from "../../components/GoogleAdsense";
import ReactGA from "react-ga4";
import { FullDateBuddhistEra as FullDateBuddhistEraUtil } from "../../utils/DateFormat";

type Props = {};

const PricePast = observer((props: Props) => {
  const navigate = useNavigate();
  const slotGoogleAdsenseDesktop =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_PRODUCT_PRICE_TODAY_DESKTOP;
  const slotGoogleAdsenseMobile =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_PRODUCT_PRICE_TODAY_MOBILE;
  const { t, i18n } = useTranslation();
  let [defaultStartDate, setDefaultStartDate] = useState<string>("");
  let [defaultEndDate, setDefaultEndDate] = useState<string>("");
  const { SnackbarStore } = useStores();
  const { productId, date } = useParams();
  const [product, setProduct] = useState<ProductModel>({
    nameEn: "",
    nameTh: "",
    description: "",
  });
  const [priceSubProducts, setPriceSubProducts] = useState<any>([]);
  const [subProducts, setSubProducts] = useState<SubProductModel[]>([]);
  const [metaTitle, setMetaTitle] = useState<string>("");
  const [metaDescription, setMetaDescription] = useState<string>("");
  const [priceList, setPriceList] = useState<any[]>([]);
  const [chartLabels, setChartLabels] = useState<any>([]);
  const [chartDatasets, setChartDatasets] = useState<any>([]);
  const [displayType, setDisplayType] = useState<string>("GRAPH");

  useMetaTags(
    {
      title: metaTitle,
      description: metaDescription,
      metas: [
        { property: "og:title", content: metaTitle },
        { property: "og:description", content: metaDescription },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: metaTitle,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: metaTitle },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ],
    },
    [metaTitle, metaDescription]
  );

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    mappingChartData(priceList, subProducts, null);
  }, [i18n.language]);

  const initial = () => {
    const { startDate, endDate }: { startDate: string; endDate: string } =
      mappingDateParams();
    setDefaultStartDate(startDate);
    setDefaultEndDate(endDate);
    fetchProductById({
      startDate,
      endDate,
    });
  };

  const fetchProductById = async (filter: FilterProductModel) => {
    const { resource, ...obj } = filter;
    SnackbarStore.onOpenSnackbarProcessing("");
    const response = await GetProductByIdService(productId || "", {
      ...obj,
    });
    if (!response.success) {
      SnackbarStore.onCloseSnackbarProcessing();
      SnackbarStore.onOpenSnackbarError(
        `${response.statusCode} ${response.message}` ||
          t("product.pastPrice.dataFetchFail")
      );
      return;
    }
    mappingPriceSubProducts(
      response.data?.priceList,
      response.data?.subProducts
    );
    mappingChartData(response.data?.priceList, response.data?.subProducts, filter);
    setProduct(response.data);
    setSubProducts(response.data?.subProducts);
    setPriceList(response.data?.priceList);
    setMetaDescription(
      response.data?.subProducts
        .map((subProduct: SubProductModel) => `ราคา${subProduct.nameTh}`)
        .join(" ")
    );
    setMetaTitle(
      `ราคา${
        response.data?.nameTh
      }ย้อนหลัง ตั้งแต่วันที่ ${FullDateBuddhistEraUtil(
        filter.startDate
      )} ถึงวันที่ ${FullDateBuddhistEraUtil(filter.endDate)}`
    );
    SnackbarStore.onCloseSnackbarProcessing();
    ReactGA.event("select_content", {
      content_type: "product",
      content_id: response.data?.id,
    });
  };

  const mappingDateParams = () => {
    const dateParams: string[] = date?.split("-") || [];
    const startDate: string = dateParams[0]
      ? dayjs(dateParams[0]).format("YYYY-MM-DD")
      : dayjs().add(-15, "days").format("YYYY-MM-DD");
    const endDate: string = dateParams[1]
      ? dayjs(dateParams[1]).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
    return { startDate, endDate };
  };

  const mappingChartData = (prices: any[], subProducts: SubProductModel[], filter: FilterProductModel | null) => {
    let { startDate, endDate } = mappingDateParams();
    if (filter) {
      startDate = filter.startDate;
      endDate = filter.endDate;
    }
    const formatDate = dayjs(endDate, 'YYYY-MM-DD').diff(dayjs(startDate, 'YYYY-MM-DD'), 'month') > 3 ? 'MM/YY' : 'DD/MM';
    const labels: string[] = Array.from(
      new Set(prices.map((price) => dayjs(price.date).format(formatDate)))
    );
    const datasets: any[] = subProducts.map(
      (subProduct: SubProductModel, index: number) => {
        const arr = prices
          .filter((price: any) => price.subProductId === subProduct.id)
          .map((price: any) => {
            return price.averagePrice;
          });
        return {
          label: i18n.language === "th" ? subProduct.nameTh : subProduct.nameEn,
          data: [...arr],
          borderColor: ColorPatternsConstant[index],
          backgroundColor: ColorPatternsConstant[index],
        };
      }
    );
    setChartLabels([...labels]);
    setChartDatasets([...datasets]);
  };

  const mappingPriceSubProducts = (
    prices: any,
    subProducts: SubProductModel[]
  ) => {
    const dateArray = Array.from(new Set(prices.map((item: any) => item.date)));
    const arrGroupSubProductPriceByDate = dateArray.map((date) => {
      return subProducts.map((subProduct: SubProductModel) => {
        const findPrice = prices.find(
          (price: any) =>
            price.subProductId === subProduct.id && price.date === date
        );
        return (
          findPrice || {
            averagePrice: 0,
            date,
            subProductId: subProduct.id,
          }
        );
      });
    });
    setPriceSubProducts([...arrGroupSubProductPriceByDate]);
  };

  return (
    <GeneralLayout>
      <Box className="bg-gradient-full">
        <Box className="container-content bg-white">
          <Container>
            <Box paddingY={3} color={StyleVariables["color-black"]}>
              <GoogleAdsense
                slotDesktop={slotGoogleAdsenseDesktop || ""}
                slotMobile={slotGoogleAdsenseMobile || ""}
              />
              <Box
                display={{ xs: "block", md: "flex" }}
                justifyContent={{ xs: "center", md: "flex-start" }}
                marginBottom={6}
                textAlign={{ xs: "center", md: "left" }}
              >
                <Typography
                  variant="h4"
                  color={StyleVariables["color-primary"]}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(PathsConstant.product.price.today(productId || ""))
                  }
                >
                  {t("product.pastPrice.title1", {
                    productName:
                      i18n.language === "th" ? product.nameTh : product.nameEn,
                  })}{" "}
                  {">"}
                </Typography>
                <Typography variant="h4" marginLeft={{ xs: 0, md: 1 }}>
                  {t("product.pastPrice.title2", {
                    productName:
                      i18n.language === "th" ? product.nameTh : product.nameEn,
                  })}
                </Typography>
              </Box>
              <ProductFilter
                productId={productId || ""}
                onFilterChange={(filter: FilterProductModel) =>
                  fetchProductById({ ...filter })
                }
                defaultStartDate={defaultStartDate}
                defaultEndDate={defaultEndDate}
                onDisplayTypeChange={(type: string) => setDisplayType(type)}
              />
              {!priceSubProducts.length &&
              !SnackbarStore.isOpenSnackbarProcessing ? (
                <Box bgcolor={StyleVariables["color-background"]} marginY={10}>
                  <Typography
                    variant="body1"
                    paddingY={10}
                    textAlign={"center"}
                    color={StyleVariables["color-neutral"]}
                  >
                    {t("product.pastPrice.noData", {
                      productName:
                        i18n.language === "th"
                          ? product.nameTh
                          : product.nameEn,
                    })}
                  </Typography>
                </Box>
              ) : (
                <>
                  {displayType === "GRAPH" ? (
                    <Box
                      marginTop={8}
                      display={"flex"}
                      width={"100%"}
                      minHeight={{ xs: 200, md: 300 }}
                    >
                      <LineGraphChart
                        labels={chartLabels}
                        datasets={chartDatasets}
                        options={{ maintainAspectRatio: false }}
                      />
                    </Box>
                  ) : (
                    <Box marginTop={8}>
                      <ProductPriceCompareTable
                        heads={[...chartDatasets]}
                        productPrices={[...priceSubProducts]}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </GeneralLayout>
  );
});

export default PricePast;
